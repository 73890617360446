/* ScrollEvent.css */

.scroll-container {
  width: 100%;
  height: 300vh;
  position: absolute;
  background-color: black;

}

.card-container{
  position: sticky;
  top: 0;
  width: 300vw;
  height: 100vh;
  position: sticky;
  flex-direction: row;
  display: flex;
  background-color: beige;

}

.card {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  /* background-color: rebeccapurple; */
  display: flex;
  align-items: self-start;
  /* overflow: scroll; */
  /* transition: transform 0.5s; */
}

.bg {
  position: relative;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: -1;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.text1 {
  position: absolute;
  /* display: flex; */
  width: 30vw;
  height: 90%;
  top: 50%;
  right: 1vw;
  transform: translate(0%, -50%);
  padding: 20px; /* Add padding for better visibility */
  background-color: #ffffff99;
  border-radius: 18px;
}

.text2 {
  position: absolute;
  /* display: flex; */
  width: 30vw;
  height: 90%;
  top: 50%;
  right: 101vw;
  transform: translate(0%, -50%);
  padding: 20px; /* Add padding for better visibility */
  background-color: #e1e1e1e3;
  border-radius: 18px;
}

.text3 {
  position: absolute;
  /* display: flex; */
  width: 30vw;
  height: 90%;
  top: 50%;
  right: 201vw;
  transform: translate(0%, -50%);
  padding: 20px; /* Add padding for better visibility */
  background-color: #ffffff99;
  border-radius: 18px;
}


h2{
  padding-top: 20%;
  margin: 10px;
  font-size:5vw;
}
p {
  margin: 10px;
}
button{
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: rgb(27, 27, 27) solid 3px;
  background-color: #8a161600;
  cursor: pointer;
  font-size: 1.5vw;
  font-weight: bold;
  color: rgb(27, 27, 27);
  transition: 0.5s;
}

.button:hover{
  background-color: #202020;
  color: white;
  border: white solid 3px;
}