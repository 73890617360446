.flwr {
    width: 100vw;
    height: auto;
    position: relative;
}

.title {
    position: absolute;
    top: -28vh;
    width: 100vw;
    color: white;
}
@media screen and (max-width: 600px) {
    .title {
        top: 0vh;
    }
    
}
.title h2 {
    position: relative;
    color: rgba(240, 240, 240, 0.85);
    font-size: 10em;
    text-align: center;
    font-weight: bold;
}
.title h5 {
    position: relative;
    color: rgba(240, 240, 240, 0.85);
    padding-top: 179vh;
    margin: 10px;
    padding-left: 1vw;
    text-align: left;
}
@media only screen and (max-width: 600px) {
    .title h2 {

        font-size: 5em;
    }
}

.projcard {
    height: auto;
    background-color: rgb(27, 27, 27);
    top: -50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: rgb(233, 233, 233);
    padding-top: 20px;
}



.projcard h3 {
    font-size: 2em;
    text-align: left;
}

.projcard li {
    font-size: 1em;
    text-align: left;
    padding-bottom: 10px;
    
}

.projbox img {
    width: 30vw;
    height: auto;
    border-radius: 15px;
}

.projbox {
    background-color: #6895CA;
    padding: 20px;   
    border-radius: 20px; 
    width: 30vw;
}

.Projects {
    height: 1vh;
    background-color: yellow;
}

.projbox a {
    color: darkorange;
}

.proj-footer {
    position: relative;
    top: 850vh;
}