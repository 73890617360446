#navlink:hover{
    color: #6895CA;
    text-emphasis: bold;
  }
  
  #logo_img:hover{
    src: url("Assets/fulldots.png");
    
  }
  nav #logo_img{
    height: 60px;
    width: auto;
    padding: 0px;
    text-align: center;
  }
  
  nav{
    width: 100%;
    position: fixed;
    padding: 1rem;
    height: 80px;
    margin: 5px;
    z-index: 1;
  }
  
  nav ul {
    background-color: #33333370;
    border-radius: 15px;
    width: 96vw;
    padding: 10px;
    display: flex;
    flex-direction: row;
    list-style: none;
    vertical-align: bottom;
    height: 60px;
    margin-top: 0px;
  }
  @media screen and (max-width: 600px) {
    nav ul {
      flex-direction: row;
      width: 100%;
      height: auto;
      margin-top: 0px;
    }
    
  }
  
  nav li{
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    padding-right: 2rem;
    /* vertical-align: middle; */
    /* background-color: orange; */
  }
  @media screen and (max-width: 600px) {
    nav li{
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    
  }
  
  nav li a{
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    /* padding-top: 0rem; */
  
  
    border: white;
    border-width: 10px;
    border-radius: 10px;
  
    margin: 0.5rem;
    padding: 0.5rem;
  
    font-size: 22px;
    color: #eee;
    text-decoration: none;
  }
  @media screen and (max-width: 600px) {
    nav li a{
      font-size: 18px;
      padding: 0.5rem;
    }
    
  }
  
  #dots{
    height: 60px;
    width: 60px;
    background-image: url("Assets/emptydots.png");
    background-size: cover;
  }
  
  
  #logo_img:hover{
    #dots{
        background-image: url("Assets/fulldots.png");
        background-size: cover;
    }
  
  }