@font-face {
    font-family: 'Montserrat';
    src: url(Assets/Montserrat/Montserrat-VariableFont_wght.ttf);
  }

.footer-plz {
  height: 20vh;
  background-color: #272727;
  z-index: 0;
  color: #949494;
  padding: 20px;
}

  .footer-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    color: white;
  }

.footer-list li {
    padding: 10px;
    margin: 10px;
    font-size: 18px;
  }

  .footer h3{
    margin-left: 55px;
  }
  a:link{
    color: white;
    text-decoration: none;
  }

  a:visited{
    color: white;
    text-decoration: none;
  }

  a:active{
    color: white;
    text-decoration: none;
  }


  .mainBg {
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
    padding-bottom: 0%;
    margin-bottom: 0%;
    background-color: #272727;
  }

  #main_message {
    width: 60%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    text-wrap: nowrap;
  }

  h2 {
    font-weight: 300;
    font-size: 70px;
    padding-bottom: 0%;
    margin-bottom: 0%;
  }

  em {
    font-weight: bold;
    padding-bottom: 0%;
    margin-bottom: 0%;
    text-decoration: none;
    font-style: normal;
  }

  /* Home.css */

.static_card {
  position: relative;
  text-align: right;
  color: #333;
}

.biabg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #272727;
  transition: all 0.5s ease;
}

.icon {
  width: 30px;
  height: 30px;
  object-position: center center;
  z-index: 1;
}

.home-footer {
  position: relative;
}
