.mtn {
    width: 100vw;
    height: auto;
    position: relative;
}

.about_title {
    text-align: center;
    position: absolute;
    top: -28vh;
    width: 100vw;
    color: white;
}

.about_title h2 {
    position: relative;
    color: rgba(240, 240, 240, 0.85);
    font-size: 10em;
    text-align: center;
    font-weight: bold;
}

.contact_title {
    text-align: center;
    position: absolute;
    top: 100vh;
    width: 100vw;
    color: white;
}

.contact_title h2 {
    position: relative;
    color: rgba(240, 240, 240, 0.85);
    font-size: 10em;
    text-align: center;
    font-weight: bold;
}

.about_p {
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    width: 70vw;
    align-items: left;
    /* background-color: aqua; */
}

.about_p p{
    display: flex;
    align-self: left;
    width: 60vw;
    font-size: 1.2em;
    text-align: left;
    margin: 0 auto;
    padding: 1vh;
}
.top p{
    width: 33vw;
    margin: 0 4.5vw;
    /* background-color: red; */
}

.mid{
    width: 7vw;
    margin: 0 4.5vw;
    /* background-color: red; */
}

.about_p h4{
    display: flex;
    align-self: center;
    width: 50vw;
    font-size: 2em;
    text-align: center;
    margin: 0 auto;
    padding: 5vh, 10vh, 4vh, 3vh;
    /* background-color: red; */
}

footer {
    position: relative;
    top: -200vh;
}

.footer {
    position: relative;
    top: 200vh;
    background-color: #272727;
    z-index: 0;
    color: #949494;
    padding: 20px;
  }
  
  .footer-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    color: white;
  }

.resume {
    color: #000;
    font-weight: bold;
}